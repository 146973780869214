// import {Popover} from '@material-ui/core';
// import Grid from '@material-ui/core/Grid';
// import makeStyles from '@material-ui/core/styles/makeStyles';
// import {get, map} from 'lodash';
// import PropTypes from 'prop-types';
// import React, {useEffect, useState} from 'react';
// import {GET_EVAL_SUBSCRIPTIONS} from '../../../Constants';
// import DisplayErrorAndProgress from '../../../fhg/components/DisplayErrorAndProgress';
// import Typography from '../../../fhg/components/Typography';
// import {requestForServer} from '../../../Utils/ServerUtil';

// const useStyles = makeStyles(theme => ({
//    defaultTextStyle: {
//       color: theme.palette.text.secondary,
//    },
// }));

// /**
//  * Popover component to display the collaborators for the catalog.
//  */
// export default function CollaboratorPopover({open, classes, anchorEl, onClose, evaluation}) {
//    const [collaborators, setCollaborators] = useState([]);

//    const [isLoading, setIsLoading] = useState(false);
//    const [displayError, setDisplayError] = useState({error: undefined, errorId: undefined});

//    const innerClasses = useStyles();

//    /**
//     * Fetch all the collaborators for the evaluation from the server.
//     */
//    useEffect(() => {
//       setDisplayError({error: undefined, errorId: undefined});
//       if (!!evaluation && open) {
//          const evalId = get(evaluation, 'evaluation.eval_id') || get(evaluation, 'evaluation.PK');
//          if (evalId) {
//             setIsLoading(true);
//             //Get all the existing collaborators.
//             requestForServer(GET_EVAL_SUBSCRIPTIONS.format({evalId}))
//                .then(result => {
//                   if (result.data && result.data.length > 0) {
//                      const collaborators = map(result.data, 'name');
//                      setCollaborators(collaborators.sort());
//                   } else if (result.error) {
//                      setDisplayError({error: result.error, errorId: 'collaborator.download.error'});
//                      console.log('Could not get evaluation subscriptions');
//                   }
//                })
//                .catch(error => {
//                   setDisplayError({error, errorId: 'collaborator.download.error'});
//                   console.log('Could not get evaluation subscriptions');
//                })
//                .finally(() => {
//                   setIsLoading(false);
//                });
//          }
//       }
//    }, [evaluation, open]);

//    return (
//       <Popover
//          open={open}
//          classes={classes}
//          anchorEl={anchorEl}
//          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
//          transformOrigin={{vertical: 'top', horizontal: 'center'}}
//          ModalProps={{disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true}}
//          disableAutoFocus
//          disableRestoreFocus
//          onClose={onClose}
//       >
//          <div style={{padding: 8, minWidth: 50, minHeight: 50}}>
//             <DisplayErrorAndProgress errorId={displayError.errorId} error={displayError.error}
//                                      showProgress={isLoading && collaborators.length <= 0}
//                                      enableRefresh values={displayError.error}/>
//             <Grid container direction={'column'} wrap={'nowrap'} spacing={2}>
//                {collaborators.map((collaborator, index) => (
//                   <Grid item>
//                      <Typography key={'collaborator' + index} className={`${innerClasses.defaultTextStyle}`} noWrap>
//                         {collaborator}
//                      </Typography>
//                   </Grid>
//                ))}
//             </Grid>
//          </div>
//       </Popover>
//    );
// }

// CollaboratorPopover.propTypes = {
//    open: PropTypes.bool,               // Indicates if the popover should be open.
//    classes: PropTypes.object,          // The style classes
//    anchorEl: PropTypes.any,            // The anchor element.
//    onClose: PropTypes.func,            // The callback when the popover is closed.
//    evaluation: PropTypes.object,       // The evaluation from which to display the collaborators.
// };


import {Popover} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {get, map} from 'lodash';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import DisplayErrorAndProgress from '../../../fhg/components/DisplayErrorAndProgress';
import Typography from '../../../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   defaultTextStyle: {
      color: theme.palette.text.secondary,
   },
}));

/**
 * Popover component to display the collaborators for the catalog.
 */
export default function CollaboratorPopover({open, classes, anchorEl, onClose, evaluation}) {
   const [collaborators, setCollaborators] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [displayError, setDisplayError] = useState({error: undefined, errorId: undefined});

   const innerClasses = useStyles();

   /**
    * Fetch all the collaborators for the evaluation from the evaluation prop.
    */
   useEffect(() => {
      setDisplayError({error: undefined, errorId: undefined});
      if (!!evaluation && open) {
         setIsLoading(true);

         // Get collaborators directly from the evaluation prop
         const sharedWithUsers = get(evaluation, 'evaluation.shared_with_users', []);
         if (sharedWithUsers.length > 0) {
            const collaborators = map(sharedWithUsers, 'name');
            setCollaborators(collaborators.sort());
         } else {
            setDisplayError({error: 'No collaborators found', errorId: 'collaborator.download.error'});
         }

         setIsLoading(false);
      }
   }, [evaluation, open]);

   return (
      <Popover
         open={open}
         classes={classes}
         anchorEl={anchorEl}
         anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
         transformOrigin={{vertical: 'top', horizontal: 'center'}}
         ModalProps={{disableAutoFocus: true, disableEnforceFocus: true, disableRestoreFocus: true}}
         disableAutoFocus
         disableRestoreFocus
         onClose={onClose}
      >
         <div style={{padding: 8, minWidth: 50, minHeight: 50}}>
            <DisplayErrorAndProgress errorId={displayError.errorId} error={displayError.error}
                                     showProgress={isLoading && collaborators.length <= 0}
                                     enableRefresh values={displayError.error}/>
            <Grid container direction={'column'} wrap={'nowrap'} spacing={2}>
               {collaborators.map((collaborator, index) => (
                  <Grid item key={`collaborator${index}`}>
                     <Typography className={innerClasses.defaultTextStyle} noWrap>
                        {collaborator}
                     </Typography>
                  </Grid>
               ))}
            </Grid>
         </div>
      </Popover>
   );
}

CollaboratorPopover.propTypes = {
   open: PropTypes.bool,               // Indicates if the popover should be open.
   classes: PropTypes.object,          // The style classes
   anchorEl: PropTypes.any,            // The anchor element.
   onClose: PropTypes.func,            // The callback when the popover is closed.
   evaluation: PropTypes.object,       // The evaluation from which to display the collaborators.
};
